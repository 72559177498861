<template>
	<dashboard-container
		title="Create admin"
	>
		<section class="h-100">
			<p>Create a new admin that will be able to authenticate against and interact with the service.</p>

			<form
				autocomplete="off"
				@submit.prevent="createUser"
			>
				<form-row>
					<!-- Username -->
					<form-input
						block
						required
						type="email"
						name="userEmail"
						label="Email Address"
						v-model="user.email"
					/>

					<!-- Password -->
					<form-input
						block
						required
						password
						name="userPassword"
						label="Password"
						v-model="user.password"
						description="Your password must be at least 8 characters long, contain upper and lower case letters, numbers and special characters"
					>
						<button
							@click="generatePassword"
							type="button"
							class="btn btn-sm btn-link px-0"
						>Generate a password</button>
					</form-input>

					<!-- Clinic -->
					<div class="form-group col col-md-6">
						<label for="userClinic">
							Clinic <span class="text-danger">*</span>
						</label>

						<select
							class="custom-select"
							:class="getErrors[`userClinic`] ? 'border-danger' : null"
							id="userClinic"
							name="userClinic"
							v-model="user.clinic"
						>
							<option selected disabled value="">Choose...</option>
							<option
								v-for="clinic in clinics"
								:key="clinic.id"
								:value="clinic.name"
							>{{ clinic.name }}</option>
						</select>

						<Alert
							error
							type="danger"
							v-show="getErrors[`userClinic`]"
							:class="getErrors[`userClinic`] ? 'invalid-error' : null"
						>
							<small>{{ getErrors[`userClinic`] }}</small>
						</Alert>
					</div>
				</form-row>

				<button type="submit" class="btn btn-primary btn-block mt-2 w-50">
					<div v-show="loading" class="spinner-border spinner-border-sm text-light" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<span :class="loading ? 'btn__loading' : null">Submit</span>
				</button>
			</form>

			<Alert
				v-if="user.created || user.error"
				:type="user.created ? 'success' : 'danger'"
				class="mt-3"
			>
				<p v-if="user.created" class="mb-0"><strong>{{ user.createdEmail }}</strong> has been created</p>
				<p v-else class="mb-0">{{ user.error }}</p>
			</Alert>
		</section>
	</dashboard-container>
</template>

<script>
// State
import { mapGetters } from 'vuex'

// Password Generator
import generatePassword from 'password-generator'

// Content Clinics JSON
import clinics from '@/inc/content/clinics'

// Admin Components
import dashboardContainer from '@/components/admin/dashboard-container'

// General Components
import formRow from '@/components/form/form-row'
import formInput from '@/components/form/form-input'
import Alert from '@/components/general/alert'

export default {
	data() {
		return {
			user: {
				email: '',
				password: '',
				clinic: '',
				error: '',
				created: false,
				createdEmail: ''
			},
			clinics: clinics,
			loading: false
		}
	},
	components: {
		dashboardContainer,
		Alert,
		formRow,
		formInput,
	},
	watch: {
		email(val) {
			if ( val.length ) {
				delete this.getErrors['userEmail']

				// Remove user creation message if true
				if ( this.user.created ) this.user.created = false
			}
		},
		password(val) {
			if ( val.length && this.passwordStrength ) {
				delete this.getErrors['userPassword']
				
				// Remove user creation message if true
				if ( this.user.created ) this.user.created = false
			}
		},
		clinic(val) {
			if ( val.length ) {
				delete this.getErrors['userClinic']

				// Remove user creation message if true
				if ( this.user.created ) this.user.created = false
			}
		}
	},
	computed: {
		...mapGetters([
			'getErrors'
		]),
		email() {
			return this.user.email
		},
		password() {
			return this.user.password
		},
		clinic() {
			return this.user.clinic
		},
		passwordStrength() {
			/**
			 * Must satisfy all criteria
			 * upper and lower case letters, numbers, special characters, at least 8 characters.
			 */
			const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!@#£$%^&*(),.?":{}|<>])(?=.{8,})');
			return strongRegex.test(this.user.password)
		}
	},
	methods: {
		generatePassword() {
			// Clear Password
			this.user.password = ''

			// Generate password based on strength value
			const randomLength = Math.floor(Math.random() * (14 - 8)) + 8;
			while (!this.passwordStrength) {
				this.user.password = generatePassword(randomLength, false, /[\w\d?-]/);
			}
		},
		async createUser() {
			// Validate user fields
			if (
				!!this.user.email &&
				!!this.user.password &&
				!!this.passwordStrength &&
				!!this.user.clinic
			) {
				try {
					const response = await this.$api.post(
						'/v1/user', {
							email: this.user.email,
							clinic: this.user.clinic,
							password: this.user.password,
						}
					)
					const data = await response.data

					// Clear all state on valid submission
					if ( data ) {
						const email = this.user.email
						this.user.createdEmail = email
						this.user.error = ''
						this.user.clinic = ''
						this.user.email = ''
						this.user.password = ''
						this.user.created = true
					}
				} catch(error) {
					if (error.result) {
						// The request was made and the server responded
						this.user.error = error.result.data['meta']['error']['error_message']
					} else if (error.request) {
						//The request was made but no response was received,
						let errorMessage = JSON.parse(error.request.response)
						errorMessage = errorMessage['meta']['error']['error_message']
						this.user.error = errorMessage
					} else {
						// Something happened in setting up the request and triggered an Error
						this.user.error = error.message
					}
				}
			} else {
				// Assign errors
				await this.$store.commit('updateErrors', {
					...this.getErrors,
						userEmail: !this.user.email.length ? 'Please enter an email' : '',
						userPassword: !this.user.password.length ? 'Please enter a valid password' : !this.passwordStrength ? 'Please enter a valid password' : '',
						userClinic: !this.user.clinic.length ? 'Please select a clinic' : ''
				})
			}
		},
	}
}
</script>